import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/SEO';
import Layout from '../components/shared/layout';
import { wrapper } from '../utils/style';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../components/shared/ButtonLink';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }
`;

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title='404: Not found'/>
    <Wrapper>
      <div className="flex">
        <div className="col-2">
          {data.missing.childImageSharp.fluid ?
            <Img alt="404" fluid={data.missing.childImageSharp.fluid}/>
            : "404"
          }
        </div>
        <div className="col-2">
          <h1>เราไม่พบหน้าเว็บที่ค้นหา</h1>
          <p>คุณอาจเข้าลิงก์ผิด หรือใส่ที่อยู่ไม่ถูกต้อง</p>
          <p>แต่เราอาจช่วยคุณได้</p>
          <ButtonLink to="/" text="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก" ariaText="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"/>
        </div>
      </div>

    </Wrapper>
  </Layout>
);

export default NotFoundPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    missing: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
          fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }   
  }
`;
